/* ... existing styles ... */

.register-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(120deg, rgba(240, 244, 248, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%),
                url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%231890ff' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    padding: 2rem;
}

.register-card {
    width: 100%;
    max-width: 900px;
    border-radius: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.register-header {
    text-align: center;
    margin-bottom: 2rem;
}

.register-header h2 {
    color: #1890ff;
    margin-bottom: 0.5rem;
}

.register-subtitle {
    color: #8c8c8c;
    font-size: 16px;
}

.register-steps {
    margin-bottom: 2rem;
    padding: 1rem 0;
}

.register-content {
    padding: 0 1rem;
}

.register-form .ant-form-item {
    margin-bottom: 16px;
}

.register-form .ant-input-prefix {
    color: #1890ff;
    margin-right: 8px;
}

.register-form .ant-input,
.register-form .ant-input-password {
    border-radius: 8px;
    height: 40px;
}

.register-form .ant-input-affix-wrapper {
    border-radius: 8px;
}

.register-form .ant-input-textarea {
    border-radius: 8px;
}

.form-actions {
    margin-top: 2rem;
    text-align: center;
}

.register-button {
    width: 200px;
    height: 45px;
    font-size: 16px;
    border-radius: 8px;
}

.login-link {
    margin-top: 1rem;
    color: #8c8c8c;
}

.otp-section {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    padding: 2rem 0;
}

.otp-description {
    color: #8c8c8c;
    margin-bottom: 2rem;
}

.timer {
    color: #1890ff;
    font-size: 16px;
    margin: 1rem 0;
}

.otp-actions {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

@media (max-width: 768px) {
    .register-container {
        padding: 1rem;
    }

    .register-card {
        box-shadow: none;
    }

    .register-steps {
        padding: 1rem;
    }

    .otp-section {
        padding: 1rem 0;
    }
}

.login_container {
  width: 100%;
  min-height: 100vh;
  background:
    linear-gradient(120deg, rgba(240, 244, 248, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%),
    url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%231890ff' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.login_form_container {
  width: 1000px;
  height: 600px;
  display: flex;
  border-radius: 24px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  background: white;
  overflow: hidden;
  position: relative;
}

.left {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  padding: 50px;
  position: relative;
}

.left::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background:
    linear-gradient(120deg, rgba(255, 255, 255, 0.8) 0%, rgba(249, 251, 253, 0.8) 100%),
    url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%231890ff' fill-opacity='0.03' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
  z-index: -1;
}

.right {
  flex: 1;
  background: linear-gradient(145deg, #1890ff 0%, #096dd9 100%);
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.form_container {
  width: 100%;
  max-width: 400px;
  position: relative;
  z-index: 1;
  animation: fadeIn 0.8s ease-out;
}

.form-header {
  margin-bottom: 40px;
  text-align: center;
}

.login-title {
  color: #1f1f1f !important;
  margin-bottom: 12px !important;
  font-size: 32px !important;
  font-weight: 600 !important;
  letter-spacing: -0.5px;
}

.login-subtitle {
  color: #666;
  font-size: 16px;
  margin-bottom: 30px;
}

.login_input {
  height: 50px;
  border-radius: 12px;
  border: 1.5px solid #eef2f6;
  background: white;
  transition: all 0.3s ease;
  font-size: 15px;
}

.login_input:hover {
  border-color: #1890ff;
  background: white;
}

.login_input:focus {
  border-color: #1890ff;
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.1);
  background: white;
}

.input-icon {
  color: #1890ff;
}

.login-button {
  width: 100%;
  height: 50px;
  border-radius: 12px;
  background: #1890ff;
  border: none;
  transition: all 0.3s ease;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
}

.login-button:hover {
  background: #096dd9;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(24, 144, 255, 0.2);
}

.login-button:active {
  transform: translateY(0);
}

.truck-svg {
  width: 70%;
  height: auto;
  opacity: 0.95;
  filter: drop-shadow(0 8px 16px rgba(0, 0, 0, 0.2));
  animation: float 6s ease-in-out infinite;
  z-index: 2;
}

.brand-text {
  position: absolute;
  top: 40px;
  left: 40px;
  color: white;
  font-size: 24px;
  font-weight: 600;
  z-index: 3;
  letter-spacing: 1px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.svg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 30% 70%, transparent 0%, rgba(0, 0, 0, 0.2) 100%);
  z-index: 1;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.circle {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
}

.circle-1 {
  width: 200px;
  height: 200px;
  top: -100px;
  right: -50px;
  animation: float-slow 8s ease-in-out infinite;
}

.circle-2 {
  width: 150px;
  height: 150px;
  bottom: -50px;
  left: -50px;
  animation: float-slow 10s ease-in-out infinite reverse;
}

.circle-3 {
  width: 100px;
  height: 100px;
  top: 50%;
  right: 30px;
  animation: float-slow 7s ease-in-out infinite;
}

@keyframes float {
  0% { transform: translateY(0px) rotate(0deg); }
  50% { transform: translateY(-20px) rotate(2deg); }
  100% { transform: translateY(0px) rotate(0deg); }
}

@keyframes float-slow {
  0% { transform: translate(0, 0); }
  50% { transform: translate(10px, 10px); }
  100% { transform: translate(0, 0); }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@media only screen and (max-width: 768px) {
  .right {
    display: none;
  }

  .login_form_container {
    width: 100%;
    max-width: 500px;
    height: auto;
    min-height: 500px;
  }

  .left {
    border-radius: 24px;
    padding: 40px 30px;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Ubuntu";
  src: url("../../fonts/Ubuntu/Ubuntu-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu";
  src: url("../../fonts/Ubuntu/Ubuntu-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu";
  src: url("../../fonts/Ubuntu/Ubuntu-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu";
  src: url("../../fonts/Ubuntu/Ubuntu-Light.ttf") format("truetype");
  font-weight: lighter;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu";
  src: url("../../fonts/Ubuntu/Ubuntu-LightItalic.ttf") format("truetype");
  font-weight: lighter;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu";
  src: url("../../fonts/Ubuntu/Ubuntu-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Comfortaa";
  src: url("../../fonts/Comfortaa/Comfortaa-Bold.woff2") format("woff2"),
    url("../../fonts/Comfortaa/Comfortaa-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Comfortaa";
  src: url("../../fonts/Comfortaa/Comfortaa-Regular.woff2") format("woff2"),
    url("../../fonts/Comfortaa/Comfortaa-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic";
  src: url("../../fonts/Noto_Kufi_Arabic/NotoKufiArabic-Bold.woff2")
      format("woff2"),
    url("../../fonts/Noto_Kufi_Arabic/NotoKufiArabic-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic";
  src: url("../../fonts/Noto_Kufi_Arabic/NotoKufiArabic-Regular.woff2")
      format("woff2"),
    url("../../fonts/Noto_Kufi_Arabic/NotoKufiArabic-Regular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.site-layout-background .ant-menu-horizontal {
  border-bottom: none;
}

.site-layout-background .ant-menu-item {
  transition: color 0.3s ease;
}

.site-layout-background .ant-menu-item:hover {
  color: #1890ff;
}

.site-layout-background .ant-menu-item-selected {
  color: #1890ff;
  font-weight: bold;
}

.site-layout-background .submenu-title-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s ease;
}

.site-layout-background .submenu-title-wrapper:hover {
  color: #1890ff;
}

.ant-tooltip a {
  color: #fff;
}

.app_ltr,
.app_ltr html,
.app_ltr div,
.app_ltr span,
.app_ltr p,
.app_ltr h1,
.app_ltr h2,
.app_ltr h3,
.app_ltr h4,
.app_ltr h5,
.app_ltr h6,
.app_ltr label,
.app_ltr input,
.app_ltr select,
.app_ltr textarea,
.app_ltr button,
.app_ltr optgroup {
  font-family: "Ubuntu" !important;
}
.app_rtl,
.app_rtl html,
.app_rtl div,
.app_rtl span,
.app_rtl p,
.app_rtl h1,
.app_rtl h2,
.app_rtl h3,
.app_rtl h4,
.app_rtl h5,
.app_rtl h6,
.app_rtl label,
.app_rtl input,
.app_rtl select,
.app_rtl textarea,
.app_rtl button,
.app_rtl optgroup {
  font-family: "Noto Kufi Arabic" !important;
}

.ant-drawer-close,
.ant-modal-close {
  background-color: #888888 !important;
  color: #ffffff !important;
}
.ant-card-body {
  padding: 20px !important;
}

.ant-drawer-header {
  background: rgb(240, 242, 245);
  color: rgb(0, 0, 0);
  font-family: "Ubuntu", Arial, sans-serif;
  font-size: 24px;
  font-weight: bold;
}

.paper-texture {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cfilter id='tornEdges' x='0' y='0' width='100%25' height='100%25'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.1' numOctaves='3' result='noise' seed='5'/%3E%3CfeDisplacementMap in='SourceGraphic' in2='noise' scale='30' xChannelSelector='R' yChannelSelector='G'/%3E%3C/filter%3E%3Cpattern id='lines' patternUnits='userSpaceOnUse' width='100' height='30'%3E%3Cpath d='M 0 15 L 100 15' stroke='%23d8b365' stroke-width='0.5'/%3E%3C/pattern%3E%3C/defs%3E%3Crect width='100%25' height='100%25' fill='%23f5f5dc'/%3E%3Crect width='100%25' height='100%25' fill='url(%23lines)'/%3E%3Crect x='0' y='0' width='100%25' height='15%25' filter='url(%23tornEdges)' fill='%23f5f5dc'/%3E%3Crect x='0' y='15%25' width='100%25' height='85%25' fill='transparent'/%3E%3C/svg%3E");
  color: #645e49;
  font-family: "Ubuntu", "Times New Roman", serif;
  font-size: 16px;
  line-height: 1.5;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  border: 1px solid #ddd;
}

/* Timeline item */
:where(.css-dev-only-do-not-override-42nv3w).ant-timeline.ant-timeline-label
  .ant-timeline-item-label {
  width: calc(20% - 12px);
}

:where(.css-dev-only-do-not-override-42nv3w).ant-timeline.ant-timeline-alternate
  .ant-timeline-item-tail,
:where(.css-dev-only-do-not-override-42nv3w).ant-timeline.ant-timeline-right
  .ant-timeline-item-tail,
:where(.css-dev-only-do-not-override-42nv3w).ant-timeline.ant-timeline-label
  .ant-timeline-item-tail,
:where(.css-dev-only-do-not-override-42nv3w).ant-timeline.ant-timeline-alternate
  .ant-timeline-item-head,
:where(.css-dev-only-do-not-override-42nv3w).ant-timeline.ant-timeline-right
  .ant-timeline-item-head,
:where(.css-dev-only-do-not-override-42nv3w).ant-timeline.ant-timeline-label
  .ant-timeline-item-head,
:where(.css-dev-only-do-not-override-42nv3w).ant-timeline.ant-timeline-alternate
  .ant-timeline-item-head-custom,
:where(.css-dev-only-do-not-override-42nv3w).ant-timeline.ant-timeline-right
  .ant-timeline-item-head-custom,
:where(.css-dev-only-do-not-override-42nv3w).ant-timeline.ant-timeline-label
  .ant-timeline-item-head-custom {
  inset-inline-start: 20%;
}

:where(.css-dev-only-do-not-override-42nv3w).ant-timeline.ant-timeline-alternate
  .ant-timeline-item-left
  .ant-timeline-item-content,
:where(.css-dev-only-do-not-override-42nv3w).ant-timeline.ant-timeline-right
  .ant-timeline-item-left
  .ant-timeline-item-content,
:where(.css-dev-only-do-not-override-42nv3w).ant-timeline.ant-timeline-label
  .ant-timeline-item-left
  .ant-timeline-item-content {
  inset-inline-start: calc(20% - 4px);
}

.react-tel-input .form-control {
  width: 200px !important;
}

/* Menu Styling */
.dashboard-menu.ant-menu {
    background: transparent;
}

.dashboard-menu.ant-menu-light .ant-menu-item {
    margin: 8px 0;
    height: 45px;
    line-height: 45px;
    border-radius: 8px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.dashboard-menu.ant-menu-light .ant-menu-item:hover {
    background: rgba(24, 144, 255, 0.1);
    color: #1890ff;
}

.dashboard-menu.ant-menu-light .ant-menu-item-selected {
    background: #1890ff;
    color: white;
    font-weight: 500;
}

.dashboard-menu.ant-menu-light .ant-menu-item-selected a {
    color: white !important;
}

.dashboard-menu.ant-menu-light .ant-menu-item::after {
    display: none;
}

/* SubMenu Styling */
.dashboard-menu.ant-menu-light .ant-menu-submenu {
    margin: 8px 0;
    border-radius: 8px;
}

.dashboard-menu.ant-menu-light .ant-menu-submenu-title {
    height: 45px;
    line-height: 45px;
    border-radius: 8px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.dashboard-menu.ant-menu-light .ant-menu-submenu-title:hover {
    background: rgba(24, 144, 255, 0.1);
    color: #1890ff;
}

.dashboard-menu.ant-menu-light .ant-menu-sub {
    background: transparent;
    border-radius: 8px;
}

/* Link Styling */
.dashboard-menu .ant-menu-item a {
    color: rgba(0, 0, 0, 0.65);
    transition: color 0.3s ease;
}

.dashboard-menu .ant-menu-item:hover a {
    color: #1890ff;
}

/* Icon Styling */
.dashboard-menu .ant-menu-item .anticon {
    transition: all 0.3s ease;
}

.dashboard-menu .ant-menu-item-selected .anticon {
    color: white;
}

/* Hover Effects */
.dashboard-menu .ant-menu-item,
.dashboard-menu .ant-menu-submenu-title {
    position: relative;
    overflow: hidden;
}

.dashboard-menu .ant-menu-item::before,
.dashboard-menu .ant-menu-submenu-title::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.1),
        transparent
    );
    transition: 0.5s;
}

.dashboard-menu .ant-menu-item:hover::before,
.dashboard-menu .ant-menu-submenu-title:hover::before {
    left: 100%;
}

/* Footer Styling */
.dashboard-footer {
    transition: all 0.3s ease;
}

.dashboard-footer:hover {
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.05);
}

.view-btn {
    padding: 6px;
    font-size: 18px;
    border-radius: 5px;
    background-color: rgba(82, 196, 26, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-btn {
    padding: 6px;
    font-size: 18px;
    border-radius: 5px;
    background-color: rgba(24, 144, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.delete-btn {
    padding: 6px;
    font-size: 18px;
    border-radius: 5px;
    background-color: rgba(255, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.goto-btn {
    padding: 6px;
    font-size: 18px;
    border-radius: 5px;
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
    color: #52c41a;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copy-btn {
    padding: 6px;
    font-size: 18px;
    border-radius: 5px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center
}

.order-ref-no {
    font-size: 16px;
    font-weight: 500;
    color: #1890ff;
    background: rgba(24, 144, 255, 0.1);
    padding: 6px 12px;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
}

.sqr-image {
    width: 100px;
    padding: 10px 0px;
    border-radius: 5%;
    border: 1px dashed #d9d9d9;
}
